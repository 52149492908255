import React, { useEffect, useState } from 'react';
import './PageInfoMobile.css'
import { useNavigate } from 'react-router-dom';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import Chip from '@mui/material/Chip';
import ReservationFormDialog from './ReservationFormDialog'

import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import WifiIcon from '@mui/icons-material/Wifi';
import EvStationIcon from '@mui/icons-material/EvStation';
import SecurityIcon from '@mui/icons-material/Security';
import EngineeringIcon from '@mui/icons-material/Engineering';


const ComponentInfo = ({ marinaData, window }) => {
    const handleBookNow = () => {
        window !== "true" ? navigate(`/reg/${marinaData.key}`) : setOpenPopup(true)

    }
    const [openPopup, setOpenPopup] = useState(false);

    useEffect(() => {
        if (openPopup) {
            console.log("Popup is open");
        }
    }, [openPopup]);

    const handleClose = () => {
        setOpenPopup(false);
    };
    const amenitiesIcons = {
        'Electricity': <ElectricBoltIcon fontSize={window !== "true" ? 'small' : 'small'} style={{ color: 'var(--brand)' }} />,
        'Water': <WaterDropIcon fontSize={window !== "true" ? 'small' : 'small'} style={{ color: 'var(--brand)' }} />,
        'WiFi': <WifiIcon fontSize={window !== "true" ? 'small' : 'small'} style={{ color: 'var(--brand)' }} />,
        'Fuel': <EvStationIcon fontSize={window !== "true" ? 'small' : 'small'} style={{ color: 'var(--brand)' }} />,
        '24/7 Security': <SecurityIcon fontSize={window !== "true" ? 'small' : 'small'} style={{ color: 'var(--brand)' }} />,
        'Boat Maintenance Services': <EngineeringIcon fontSize={window !== "true" ? 'small' : 'small'} style={{ color: 'var(--brand)' }} />,

    };
    const navigate = useNavigate();

    return (
        <div className='container_info'>

            <h1 className='title_info center'>{marinaData.name}</h1>

            <div style={{ display: 'flex', alignItems: 'center' }}>
                {marinaData.maxLOA && <Chip
                    icon={<AlignVerticalBottomIcon style={{ color: 'var(--brand)' }} />}
                    label={`Max LOA :${marinaData.maxLOA}`}
                />}
                {marinaData.maxDraft && <Chip
                    style={{ margin: '5px' }}
                    icon={<AlignHorizontalRightIcon style={{ color: 'var(--brand)' }} />}
                    label={`Max Draft :${marinaData.maxDraft}`}
                />}
            </div>

            <p className={window !== "true" ? "about-info-window" : "about-info-window"}>{marinaData.about}</p>
            <h3 className={window !== "true" ? "subtitle-info-window" : `subtitle-info-window`}>Services</h3>
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-end',
                width: '100%',
                justifyContent: 'flex-start'
            }}>
                {marinaData.amenities.map((amentie) => {
                    return <GenerateService window={window} icon={amenitiesIcons[amentie]} key={marinaData.key} title={amentie} />;
                })}



            </div>

            <button onClick={handleBookNow} className={window !== "true" ? "button w-button styledbutton" : "button w-button styledbutton-window"}>BOOK NOW</button>
            <ReservationFormDialog openPopup={openPopup} handleClose={handleClose} point={marinaData} />

        </div>
    )
}
export function GenerateService({ icon, title, window }) {


    return (
        <div className={window !== "true" ? "div-service-window" : "div-service-window"}>
            <div>{icon}</div>
            <div>{title}</div>

        </div>

    );
}

export default ComponentInfo