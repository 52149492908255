import React from 'react';

// Inline styles for the footer
const footerStyle = {
    width: '100%',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center',
    position: 'relative',
    left: '0',
    bottom: '0',
    right: '0',
    marginTop: 'auto',
    color: 'white',
    backgroundColor: 'var(--brand)',
};

const Footer = () => {
    return (
        <footer style={footerStyle}>
            © 2024 BookMyMarina.com | All Rights Reserved
        </footer>
    );
};

export default Footer;
