import React, { useState } from 'react';
import logo_bookmymarina from '../images/logo_bookmymarina.png';
import menuButton from '../icons/menu.png';
import menuButtonWhite from '../icons/menu-white.png';

import './Navbar.css';

const Navbar = ({ featuresRef, stepsRef, bookRef }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        console.log(isMenuOpen)
    };

    const scrollToSection = (sectionRef) => {
        if (sectionRef && sectionRef.current) {
            window.scrollTo({
                top: sectionRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };


    return (
        <section className="header-nav container">
            < nav className='navbar' >
                <div className='top-side'>
                    <img src={logo_bookmymarina} className="logo"
                        alt="logo" />
                    <div className={`menu-button ${isMenuOpen ? 'menu-button-clicked' : ''}`} onClick={toggleMenu}>
                        <img src={!isMenuOpen ? menuButton : menuButtonWhite} alt="menuButton" className='menu-hamburger' />
                    </div>
                </div>
                <div className={`nav-links ${isMenuOpen ? 'mobile-menu' : ''}`}>
                    <ul>
                        <li>
                            <a href="/" aria-current="page" className='active'>Home</a>
                        </li>
                        <li>
                            <a style={{ cursor: 'pointer' }} onClick={() => scrollToSection(featuresRef)} className='a-css'>Features</a>
                        </li>
                        <li>
                            <a style={{ cursor: 'pointer' }} onClick={() => scrollToSection(stepsRef)} className='a-css' >Steps</a>
                        </li>
                        <li>
                            <a style={{ cursor: 'pointer' }} onClick={() => scrollToSection(bookRef)} className='a-css' >Book</a>
                        </li>
                        <li>
                            <a href="mailto:contact@bookmymarina.com?subject=Contact" className='a-css'>Contact</a>
                        </li>

                    </ul>

                </div>

            </nav >
        </section >
    );
}

export default Navbar;
