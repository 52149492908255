import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryIcon from '@mui/icons-material/History';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography, Link } from '@mui/material';
import animationData from '../icons/Animation.json';
import Lottie from 'react-lottie';
import logo_bookmymarina from '../images/logo_bookmymarina.png'
import './PageInfoMobile.css'

const ConfirmationPage = ({ ismobile }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: ismobile = "false" ? '100%' : '100vh',
                textAlign: 'center',
                padding: '20px',
            }}
        >
            <div className='wrapper_logo'>
                <img src={logo_bookmymarina} className="logo_center"
                    alt="logo" />
            </div>

            <Lottie options={defaultOptions} height={200} width={200} />
            <Typography variant="h4" sx={{ marginY: 2 }}>
                Submission Successful!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
                Your information has been successfully submitted
            </Typography>



            <Button
                variant="contained"
                startIcon={<ArrowBackIcon />}
                href="/"

                sx={{ backgroundColor: '#1976d2', marginTop: '66px', '&:hover': { backgroundColor: '#154779' } }}
            >
                Go back to Home
            </Button>
        </Box>
    );
};

export default ConfirmationPage;
