import React, { useEffect, useState, useRef } from 'react';
import { APIProvider, Map, useMap, AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { TextField, useTheme, useMediaQuery, Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';


import IconClusterer from './cluster_images/m2.png'


import MarkerClusterer from '@googlemaps/markerclustererplus';
import marinas from '../data/marina'
import anchorImage from '../icons/anchor.png';
import InfoPage from './InfoPage';
import InfoPage2 from './InfoPage2';
const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const mapId = process.env.REACT_APP_MAPID;
const position = { lat: 45.0468317, lng: 1.8893004 }
const groupedOptions = marinas.reduce((acc, marina) => {
    (acc[marina.country] = acc[marina.country] || []).push(marina);
    return acc;
}, {});
const options = Object.entries(groupedOptions).map(([country, marinas]) => ({
    label: country,
    options: marinas.map((marina) => ({ label: marina.name, ...marina })),
}));

export function Maps2() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedMarker, setSelectedMarker] = useState(null);
    return (
        <div style={{ height: '550px', width: '100%', borderRadius: '30px', overflow: 'hidden' }}>
            <APIProvider apiKey={apiKey}>
                <Map
                    Center={position}
                    defaultZoom={3}
                    mapId={mapId}
                >
                    <div style={{
                        position: 'absolute',
                        top: isMobile ? 30 : 10,
                        right: isMobile ? '10%' : '18%',
                        zIndex: 1,
                        width: '80%',
                        maxWidth: '800px',
                        borderRadius: '20px',
                        overflow: 'hidden',
                    }}>
                        <Autocomplete
                            id="map-marinas-autocomplete"
                            options={options.flatMap(group => group.options)}
                            getOptionLabel={(option) => option.label}
                            groupBy={(option) => option.country}
                            filterOptions={(options, { inputValue }) => {
                                return options.filter(option =>
                                    option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                                    option.country.toLowerCase().includes(inputValue.toLowerCase())
                                );
                            }}

                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Select Marina"
                                    variant="filled"
                                    size="small"
                                    style={{
                                        background: 'white',
                                        width: '100%',
                                        borderRadius: '20px',
                                    }}
                                />}
                            style={{ background: 'white', width: '100%', }}
                            onChange={(event, value) => {
                                console.log(value)
                                if (value == null) { console.log('null') }
                                else { setSelectedMarker(value.key); }
                            }}
                        />
                    </div>



                    <Markers isMobile={isMobile} points={marinas} selectedMarker={selectedMarker} setSelectedMarker={setSelectedMarker} />
                </Map>
            </APIProvider>
        </div>
    );
}

const Markers = ({ points, selectedMarker, setSelectedMarker }) => {
    const map = useMap();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const clusterer = useRef(null);
    const navigate = useNavigate();
    const mcOptions = {
        styles: [{
            url: IconClusterer,
            height: 50,
            width: 50,
            anchorText: [21, 3]


        }

        ]
    }
    useEffect(() => {
        if (!map) return;
        if (!clusterer.current) {
            clusterer.current = new MarkerClusterer(map, [], mcOptions);
        }
    }, [map]);

    useEffect(() => {
        const newMarkers = points.map((point) => {
            const marker = new window.google.maps.Marker({
                position: { lat: point.lat, lng: point.lng },
                map: map,
                icon: {
                    url: anchorImage,
                    scaledSize: new window.google.maps.Size(40, 40),
                },
            });

            marker.addListener('click', () => setSelectedMarker(point.key));
            return marker;
        });

        if (clusterer.current) {
            clusterer.current.clearMarkers();
            clusterer.current.addMarkers(newMarkers);
        }

        return () => newMarkers.forEach((marker) => marker.setMap(null));
    }, [points, map]);

    return (
        <>
            {points.map((point) => {
                const isSelected = selectedMarker === point.key;
                return isSelected && (
                    <InfoWindow
                        options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                        key={point.key}
                        position={{ lat: point.lat, lng: point.lng }}
                        onCloseClick={() => setSelectedMarker(null)}
                    >
                        <InfoPage2 point={point} setSelectedMarker={setSelectedMarker} isMobile={isMobile} />
                    </InfoWindow>
                );
            })}
        </>
    );
};
