import React from 'react';
import DesignOne from '../images/Design sans titre (3).png'


function FeaturesSection({ bookRef }) {
    const scrollToBookSection = () => {
        if (bookRef && bookRef.current) {
            window.scrollTo({
                top: bookRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };
    return (

        <section className="content-section-bg">
            <div className="container">

                <div className="w-layout-grid content-grid">
                    <article className="content-block bg-text">
                        <h2>Discover the Core Features of Our App</h2>
                        <p>Enjoy effortless searching, comprehensive marina details, and a streamlined booking process. Our platform ensures a smooth and satisfying marina reservation journey from start to finish. Find your ideal marina spot with ease, just a few taps away.</p>
                        <a onClick={scrollToBookSection} className="button button-space w-button">View Marinas</a>
                    </article>
                    <div className="image-block">
                        <img src={DesignOne}
                            sizes="(max-width: 767px) 94vw, 523.0034790039062px"
                            alt="Feature Highlight" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturesSection;
