import React, { useRef } from 'react'; // Importez useRef
import Navbar from './Navbar';
import Banner from './Banner';
import HeroSection from './HeroSection';
import FeaturesSection from './FeaturesSection';
import Howitworks from './Howitworks';
import Findyournextmarina from './Findyournextmarina';
import Footer from './Footer';

const LandingPage = () => {
    const heroSectionRef = useRef(null);
    const featuresRef = useRef(null);
    const stepsRef = useRef(null);
    const bookRef = useRef(null);

    return (
        <>
            <Banner />
            <Navbar heroRef={heroSectionRef} featuresRef={featuresRef} stepsRef={stepsRef} bookRef={bookRef} />
            <div ref={heroSectionRef}><HeroSection bookRef={bookRef} /></div>
            <div ref={featuresRef}><FeaturesSection bookRef={bookRef} /></div>
            <div ref={stepsRef}><Howitworks /></div>
            <div ref={bookRef}><Findyournextmarina /></div>
            <Footer />
        </>
    );
}

export default LandingPage;
