import React, { useState } from 'react';
import { Dialog, InputAdornment, DialogContent, TextField, Button, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import boat from '../images/boat2.jpg';
import removeIcon from '../icons/remove.png';
import PersonIcon from '@mui/icons-material/Person';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import SailingOutlinedIcon from '@mui/icons-material/SailingOutlined';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import ConfirmationPage from './ConfirmationPage'
import { utcToZonedTime } from 'date-fns-tz'
import { format } from 'date-fns'





function ReservationFormDialog({ openPopup, handleClose, point }) {
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const marinaData = point
    const parisDate = utcToZonedTime(new Date(), 'Europe/Paris')



    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        vesselName: '',
        vesselDraft: '',
        vesselLength: '',
        arrivalDate: '',
        departureDate: '',
        marina: marinaData.name,
        createdDate: format(parisDate, 'yyyy-MM-dd HH:mm:ss zzz', { timeZone: 'Europe/Paris' })
        ,
    });

    const [errors, setErrors] = useState({});

    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const phoneRegex = /^\+?\d{10,15}$/;
    const validate = () => {
        let tempErrors = {};
        tempErrors.firstName = formValues.firstName ? "" : "This field is required";
        tempErrors.lastName = formValues.lastName ? "" : "This field is required";
        tempErrors.email = formValues.email ? (emailRegex.test(formValues.email) ? "" : "Invalid email format ") : "This field is required";
        tempErrors.phone = formValues.phone ? (phoneRegex.test(formValues.phone) ? "" : "Invalid format. Use '+', e.g., +1234567890.") : "This field is required";
        tempErrors.vesselName = formValues.vesselName ? "" : "This field is required";
        tempErrors.vesselDraft = formValues.vesselDraft ? "" : "This field is required";
        tempErrors.vesselLength = formValues.vesselLength ? "" : "This field is required";
        tempErrors.arrivalDate = formValues.arrivalDate ? "" : "This field is required";
        tempErrors.departureDate = formValues.departureDate ? "" : "This field is required";

        setErrors(tempErrors);

        return Object.values(tempErrors).every(x => x === "");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            setIsLoading(true);
            setFormValues({ ...formValues, marina: marinaData.name });
            console.log(formValues);

            fetch('https://script.google.com/macros/s/AKfycbwYk6oKwqSMbLoCrljQ4nVI-RSkMaWHEJVdRn6VIZKdSuESMk_3ajP3DZ295ZNP1c7ogw/exec', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formValues),
            })

                .then(data => {
                    console.log('Success:', data);
                    setShowConfirmation(true)
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setIsLoading(false);

                });
        }
    };

    return (
        <Dialog open={openPopup} onClose={handleClose} maxWidth="md" fullWidth PaperProps={{ style: { overflow: "hidden", width: '1000px', borderRadius: '18px' } }}>
            <DialogContent style={{ padding: 0, overflow: "hidden" }}>
                {showConfirmation ? (

                    <ConfirmationPage ismobile="false" />
                ) : (
                    <Grid container spacing={0}>
                        {!isMobile && (
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', height: '100%' }}>
                                    <img src={boat} alt="Boat" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <img
                                    style={{ width: '14px', padding: "10px", cursor: "pointer" }}
                                    src={removeIcon}
                                    alt="Close"
                                    onClick={handleClose}
                                />
                            </div>

                            <div style={{ padding: '0px 16px' }}>
                                <h2 style={{
                                    color: '#4788ff',
                                    marginTop: '0px',
                                    marginBottom: '10px',
                                    fontSize: "14px",

                                }}>Book your berthing spot at {point.name} spot</h2>
                                {!isMobile && (<div style={{ fontSize: '12px', textAlign: 'justify', marginBottom: '10px' }}>{point.resume}</div>)}
                            </div>
                            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '16px' }}>
                                <Grid container spacing={2}>
                                    {[
                                        { type: 'text', id: "firstName", icon: <PersonIcon style={{ color: 'var(--brand)' }} />, label: "First Name", name: "firstName" },
                                        { type: 'text', id: "lastName", icon: <PersonIcon style={{ color: 'var(--brand)' }} />, label: "Last Name", name: "lastName" },
                                        { type: 'email', id: "email", icon: <EmailOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Email", name: "email" },
                                        { type: 'phone', id: "phone", icon: <PhoneAndroidOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Phone", name: "phone" },
                                    ].map((field, index) => (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                key={field.id}
                                                id={field.id}
                                                name={field.name}
                                                label={field.label}
                                                type={field.type}
                                                value={formValues[field.name]}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment style={{ color: 'var(--brand)' }} position="start">{field.icon}</InputAdornment>,
                                                }}
                                                variant="standard"
                                                error={!!errors[field.name]}
                                                helperText={errors[field.name]}
                                                required
                                            />
                                        </Grid>
                                    ))}

                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="vesselName"
                                            name="vesselName"
                                            label="Vessel Name"
                                            value={formValues["vesselName"]}
                                            onChange={handleChange}
                                            InputProps={{
                                                startAdornment: <InputAdornment style={{ color: 'var(--brand)' }} position="start"><SailingOutlinedIcon /></InputAdornment>,
                                            }}
                                            variant="standard"
                                            error={!!errors["vesselName"]}
                                            helperText={errors["vesselName"]}
                                            required
                                        />
                                    </Grid>
                                    {[
                                        { type: 'number', id: "vesselDraft", icon: <VerticalAlignBottomOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Vessel Draft (m)", name: "vesselDraft" },
                                        { type: 'number', id: "vesselLength", icon: <StraightenOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Vessel Length (m)", name: "vesselLength" },
                                        { id: "arrivalDate", icon: <DirectionsBoatFilledOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Arrival Date", name: "arrivalDate", type: "date" },
                                        { id: "departureDate", icon: <DirectionsBoatFilledOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Departure Date", name: "departureDate", type: "date" },
                                    ].map((field, index) => (
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                key={field.id}
                                                id={field.id}
                                                name={field.name}
                                                label={field.label}
                                                type={field.type}
                                                value={formValues[field.name]}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment style={{ color: 'var(--brand)' }} position="start">{field.icon}</InputAdornment>,
                                                }}
                                                variant="standard"
                                                error={!!errors[field.name]}
                                                helperText={errors[field.name]}
                                                required
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                                <Button type="submit" variant="contained" disabled={isLoading}>
                                    {isLoading ? <CircularProgress size={24} /> : 'SUBMIT'}
                                </Button>
                            </form>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default ReservationFormDialog;
