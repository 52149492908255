import React from 'react'
import { Maps2 } from '../Components/Maps2'

const Findyournextmarina = () => {
    return (
        <div className="content-section-bg">
            <div className="container">
                <div className="title-wrap-centre">
                    <h2>Find your next marina.</h2>
                </div>
                <Maps2 />

            </div>
        </div>)
}

export default Findyournextmarina