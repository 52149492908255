import React from 'react';
import './HeroSection.css';
import travelerMontenegro from '../images/handsome-male-traveler-montenegro.jpg'
const HeroSection = ({ bookRef }) => {

    const scrollToBookSection = () => {
        if (bookRef && bookRef.current) {
            window.scrollTo({
                top: bookRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <section id="Header" className="header">
            <div className="container-flex">
                <div className="hero-content">
                    <h1 className="hero-h1">
                        Find the Perfect Spot in <span className="brand-span">Marinas</span> Around the World.
                    </h1>
                    <p className="hero-paragraph">
                        Whether you're looking for a berth for a night or a season, we provide
                        direct access to thousands of marinas across the globe. Discover, book, and manage your dream spots
                        with just a few clicks.
                    </p>
                    <div className="button-wrapper"  >
                        <a onClick={scrollToBookSection} className="button w-button">View Marinas</a>
                    </div>
                </div>
                <div className="hero-image-wrap">
                    <img
                        src={travelerMontenegro}
                        alt="A male traveler in Montenegro"
                        className="hero-image"
                        sizes="(max-width: 991px) 94vw, 47vw"
                    />
                </div>
            </div>
        </section>)
};

export default HeroSection;
