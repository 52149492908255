const marinas = [
    {
        country: 'Morocco',
        city: 'Tangier',
        name: 'tanja marina bay',
        address: 'Marina of Tangier City Port – 90000 Tangier, Morocco',
        resume: 'Your gateway to the Mediterranean, Experience luxury yachting at Tanja Marina Bay.',
        lat: 35.7836,
        lng: -5.7853,
        maxLOA: '90 meters',
        maxDraft: '8.5 meters',
        amenities: [
            'Cafes & Restaurants',
            'Shops',
            'Nautical Club',
            'Boat Repair',
            'Wintering Zone',
            'Entertainment'
        ],
        about: 'Situated at the western entrance of the Strait of Gibraltar, Tanja Marina Bay is a premier yachting destination in Morocco. This tranquil marina caters exclusively to leisure and cruise activities, offering a peaceful and exclusive environment for visitors. The marina boasts over 1,400 berths accommodating vessels ranging from 7 to 90 meters. Beyond the stunning location, Tanja Marina Bay provides a vibrant waterfront community with cafes, restaurants, shops, and entertainment options. Additionally, the marina offers various services for yacht owners, including a boat repair zone, a wintering zone, and sailing and boat piloting schools. For those seeking a luxurious yachting experience and easy access to the charming city of Tangier and its historical medina, Tanja Marina Bay is the perfect haven.'
        , key: '1'
    },
    {
        country: 'Morocco',
        city: 'M\'diq',
        name: 'marina smir',
        address: 'Marina Smir, located in northern Morocco',
        resume: 'Discover tranquility and adventure at Marina Smir.',
        lat: 35.7508,
        lng: -5.3386,
        maxLOA: '60 meters',
        maxDraft: '2 meters',
        amenities: [
            'Mooring',
            'Utilities',
            'Fueling',
            'Maintenance',
            'Storage',
            'Transportation'
        ],
        about: 'Nestled in northern Morocco, Marina Smir is a haven for yachting enthusiasts seeking both tranquility and exploration. Situated in a calm and protected harbor, the marina offers 450 berths accommodating vessels from 6 to 60 meters. Its convenient location allows for easy day trips to nearby cities like Tetouan, Chefchaouen, Tangier, and Asilah, each offering unique cultural and historical experiences. Beyond its picturesque setting, Marina Smir provides a comprehensive range of amenities to ensure a comfortable and convenient stay. Visitors can access on-site shops, restaurants, repair facilities, and fueling services, catering to all their yachting needs. Whether you seek relaxation or adventure, Marina Smir serves as the perfect base to explore the captivating beauty of Morocco.'
        , key: '2'
    },
    {

        country: 'Morocco',
        city: 'Nador',
        name: 'Marina Marchica',
        address: 'located on the Mediterranean coast, near Nador city center',
        resume: 'Beauty of the Mediterranean at Marina Marchica.',
        lat: 35.31319,
        lng: -2.92094,
        maxLOA: '90 meters',
        maxDraft: '6 meters',
        amenities: [
            'Mooring',
            'Utilities',
            'Fueling',
            'Maintenance & Repair',
            'Security',
            'Shopping & Dining'
        ],
        about: "Marina Marchica is a hidden gem located on the Mediterranean coast in Nador, Morocco. Offering 700 berths for vessels ranging from 6 to 25 meters, the marina boasts a calm and protected environment, ideal for a relaxing getaway. Beyond its tranquil setting, Marina Marchica provides easy access to the vibrant city of Nador, where visitors can explore historical sites, bustling markets, and delicious local cuisine. The marina also serves as a gateway to the region's stunning beaches and captivating tourist attractions, making it a perfect base for exploring the beauty of Morocco."
        , key: '3'
    },

    {
        country: 'Morocco',
        city: 'Saïdia',
        name: 'MARINA Saïdia',
        address: 'Saïdia, Oriental Region, Morocco',
        resume: 'Marina Saïdia: A Mediterranean Gem',
        lat: 35.1022,
        lng: -2.295,
        maxLOA: '30 meters',
        maxDraft: '4 meters',
        amenities: [
            'Security',
            'Fuel & Water',
            'Electricity & Wi-Fi',
            'Restaurants & Shops',
            'Yacht Maintenance'
        ],
        about: "Marina Saïdia is a captivating gem bordering the crystal-clear waters of the Mediterranean Sea. This stunning port boasts 412 berths accommodating vessels up to 30 meters, offering a tranquil haven for both leisure boaters and yachters. Beyond its picturesque setting, Marina Saïdia provides a comprehensive range of amenities catering to your every need. From convenient fueling and water stations to reliable security and high-speed Wi-Fi, the marina ensures a comfortable and well-connected stay. For those seeking relaxation and rejuvenation, Saïdia itself is a paradise, known for its pristine beaches, luxurious hotels, and charming atmosphere. Indulge in delectable cuisine at waterfront restaurants, explore the vibrant local markets, or simply unwind on the golden sands, soaking in the serenity of this captivating coastal town. Whether you're a seasoned sailor, a passionate angler, or a family seeking a memorable vacation, Marina Saïdia promises an unforgettable experience."
        , key: '4'
    },
    {
        country: 'Morocco',
        city: 'Rabat',
        name: 'Bouregreg Marina',
        address: 'Bouregreg Marina, Salé, Morocco (across the Bouregreg River from Rabat)',
        resume: 'Immerse yourself in history and beauty at Bouregreg Marina.',
        lat: 34.0305,
        lng: -6.8217,
        maxLOA: '100 meters',
        maxDraft: '4 meters',
        amenities: [
            '24/7 Security',
            'Fuel & Water Stations',
            'Electricity & Wi-Fi',
            'Restaurants & Shops',
            'Yacht Maintenance'
        ],
        about: "Bouregreg Marina, nestled on the banks of the Bouregreg River in Rabat, Morocco, serves as a gateway to the city's vibrant culture and rich history. Completed in 2008, this award-winning marina is one of the largest in Morocco, boasting a capacity of 1,300 berths and offering a comprehensive range of amenities for discerning boaters. Beyond its convenient docking facilities, Bouregreg Marina provides a lively atmosphere with waterfront restaurants, cafes, and shops. The marina's strategic location also grants easy access to Rabat's captivating historical landmarks, including the Kasbah of the Oudayas, the Chellah Necropolis, and the Hassan Tower, making it the perfect base for cultural exploration and luxurious yachting experiences."
        , key: '5'
    }, {
        country: 'Morocco',
        city: 'Agadir',
        name: 'MARINA AGADIR',
        address: 'Agadir, Morocco',
        resume: 'Where the mountains meet the sea at Marina Agadir.',
        lat: 30.4269,
        lng: -9.6186,
        maxLOA: '30 meters',
        maxDraft: '3 meters',
        amenities: [
            'Restaurants & Cafes',
            'Shops & Boutiques',
            'Water Sports',
            'Yacht Club',
            'Nightlife',
            'Docking Facilities'
        ],
        about: "Marina Agadir is a captivating leisure port offering a vibrant blend of relaxation, entertainment, and stunning scenery. This popular destination boasts breathtaking views of the Atlantic Ocean and the surrounding mountains, creating an idyllic setting for your yachting experience. Beyond its natural beauty, Marina Agadir provides a diverse range of activities and amenities to cater to all preferences. Indulge in delectable cuisine at waterfront restaurants, browse through unique shops and boutiques, or embark on thrilling water sports adventures. The marina also features a yacht club, catering to the needs of boaters, and a lively nightlife scene with bars, clubs, and casinos, ensuring entertainment for all ages."
        , key: '6'
    }, {
        country: 'Netherlands',
        city: 'Rotterdam',
        name: 'City Marina Rotterdam',
        address: 'Leuvehaven 77-84, 3011 ED Rotterdam, Netherlands',
        resume: 'Your luxurious gateway to Rotterdam.',
        lat: 51.85647,
        lng: 4.47983,
        maxLOA: '50 meters',
        maxDraft: '4 meters',
        amenities: [
            '24/7 Security',
            'Concierge',
            'Utilities',
            'Fuel Station',
            'Restaurant & Bar',
            'Wellness & Fitness'
        ],
        key: '7', about: "City Marina Rotterdam, a prestigious four-star marina, offering unparalleled access to the city's vibrant culture and dynamic atmosphere. This luxurious marina caters to yachts ranging from 5 to 50 meters, providing spacious berths and a comprehensive range of amenities designed to ensure a comfortable and convenient stay. City Marina Rotterdam places you within walking distance of Rotterdam's captivating attractions, world-class museums, and bustling nightlife. Whether you're an art enthusiast, a history buff, or simply seeking a taste of Dutch culture, the city offers something for everyone."
    }
    , {
        country: 'Netherlands',
        city: 'Rotterdam',
        name: 'MARINA ROTTERDAM',
        address: 'Noorderkade 120, 3071 EL Rotterdam, Netherlands',
        resume: 'Your practical haven in Rotterdam.',
        lat: 51.85794,
        lng: 4.47281,
        maxLOA: '30 meters',
        amenities: [
            '24/7 Security',
            'Utilities',
            'Waste Disposal',
            'Laundry Facilities',
            'Yacht Repair Shop',
            'Chandlery Nearby'
        ], key: '8',
        about: "MARINA ROTTERDAM offers a convenient and practical option for boaters seeking a reliable and secure berth in Rotterdam. Situated on the Noorderkade, this well-established marina provides berths suitable for vessels up to 30 meters in length. One of the key advantages of MARINA ROTTERDAM is the on-site presence of a dedicated yacht repair shop. This repair shop caters to vessels up to 40 meters, offering a valuable resource for maintenance and repairs."
    }, {
        country: 'Netherlands',
        city: 'Rotterdam',
        name: 'The Red Apple Marina',
        address: 'Wijnhaven, Rotterdam, Netherlands',
        resume: 'Your charming haven in the heart of Rotterdam.',
        lat: 51.85533,
        lng: 4.47483,
        maxLOA: '20 meters',
        amenities: [
            '24/7 Security',
            'Utilities',
            'Public Restrooms & Showers',
            'Shared BBQ Area',
            'Tranquil Environment',
            'Scenic Views'
        ], key: '9',
        about: "The Red Apple Marina, nestled within the vibrant Wijnhaven (Wine Harbor) district of Rotterdam, offers a charming and tranquil escape for boaters seeking a central location with a relaxed atmosphere. This recently expanded marina, situated in the shadow of the iconic 'The Red Apple' building, provides berths for vessels up to 20 meters. The marina's greatest asset lies in its location. Situated within walking distance of Rotterdam's bustling city center, The Red Apple Marina allows you to experience the city's vibrant culture and attractions while returning to a tranquil haven at the end of the day."
    }
    , {
        country: 'Netherlands',
        city: 'Amsterdam',
        name: 'Sixhaven',
        address: 'Haparandadam 10, 1013 AA Amsterdam, Netherlands',
        resume: 'Your convenient connection to Amsterdam.',
        lat: 52.54233,
        lng: 4.89808,
        maxLOA: '30 meters',
        amenities: [
            '24/7 Security',
            'Utilities',
            'Water Connection',
            'Waste Disposal',
            'Free Ferry',
            'Nearby Amenities'
        ], key: '10',
        about: "Sixhaven offers a convenient and central location for boaters seeking a reliable and secure berth in Amsterdam. Situated directly opposite Amsterdam Central Station, Sixhaven provides easy access to the city center with a short ferry ride available 24/7. Sixhaven's greatest advantage lies in its proximity to Amsterdam's heart. The complimentary ferry service whisks you away to Amsterdam Central Station in just 5 minutes, placing you within walking distance of the city's vibrant attractions, world-class museums, and bustling nightlife."
    }
    , {
        country: 'Netherlands',
        city: 'Amsterdam',
        name: 'Amsterdam Marina',
        address: 'Werfkade 4 & Kraanspoor 40-42, Amsterdam, Netherlands',
        resume: 'Your accessible haven in Amsterdam.',
        lat: 52.37314,
        lng: 4.90058,
        maxLOA: '30 meters',
        amenities: [
            '24/7 Security',
            'Utilities',
            'Water Connection',
            'Waste Disposal',
            'Public Transportation',
            'Nearby Amenities'
        ], key: '11',
        about: "Amsterdam Marina offers a convenient and central location for boaters seeking a reliable and secure berth in Amsterdam. With two locations (Werfkade 4 and Kraanspoor 40-42), you can choose the one that best suits your needs. Both locations cater to yachts up to 30 meters, providing a comfortable environment for your vessel. Amsterdam Marina offers a practical solution for boaters seeking a central location with personalized service and easy access to the best of Amsterdam."
    }, {
        country: 'Netherlands',
        city: 'Veere',
        name: 'JACHTCLUB VEERE',
        address: 'Midden in het historisch centrum, 4351 AB Veere, Netherlands',
        resume: 'Unwind in a historical setting at Jachtclub Veere.',
        lat: 51.51867,
        lng: 3.80614,
        maxLOA: '20 meters',
        maxDraft: '2.5 meters',
        amenities: [
            'Limited On-Site Amenities',
            'Electricity & Wi-Fi',
            'Water Connection',
            'Waste Disposal',
            'Proximity to Historical Attractions',
            'On-Site Restaurant'
        ], key: '12',
        about: "Jachtclub Veere offers a unique experience for boaters seeking a charming and historical setting in the heart of Veere. For sailing enthusiasts, the yacht club organizes races and events throughout the year (participation might require membership). Jachtclub Veere provides a haven for boaters seeking a unique blend of historical charm, a central location, and a friendly community atmosphere."
    }
    , {
        country: 'Netherlands',
        city: 'Vlissingen',
        name: 'Michiel de Ruyter Harbor Marina',
        address: 'Binnenhaven Oostzijde 1, 4351 EA Vlissingen, Netherlands',
        resume: 'Experience comfort and a vibrant atmosphere at Michiel de Ruyter Harbor Marina.',
        lat: 51.32033,
        lng: 3.58947,
        maxLOA: '50 meters',
        maxDraft: '1.6 meters at low tide',
        amenities: [
            '24/7 Security',
            'Utilities',
            'Modern Facilities',
            'Laundry & Chandlery',
            'Yacht Maintenance',
            'Nearby Amenities'
        ], key: '13',
        about: "Michiel de Ruyter Harbor Marina, situated in the heart of Vlissingen, offers a central location with a vibrant atmosphere for boaters seeking a comfortable and convenient stay. This marina caters to yachts ranging from 5 to 50 meters, providing spacious berths and a range of amenities designed to cater to your needs. The marina prioritizes security and offers 24/7 surveillance for peace of mind. Essential utilities like electricity, Wi-Fi, and water connections are readily available to ensure the smooth operation of your vessel. Modern and well-maintained showers and restrooms are conveniently located within the marina facilities. For additional needs, an on-site chandlery provides access to essential marine supplies, while contracted yacht maintenance and repair services are available to address any maintenance requirements. Michiel de Ruyter Harbor Marina's true charm lies in its exceptional location. Situated within walking distance of supermarkets, restaurants, cafes, museums, shops, and historical attractions, the marina places you at the heart of the action. Public transportation options are also nearby, offering easy access to other parts of the city. This marina is perfect for boaters seeking a central location, a vibrant atmosphere, and convenient access to all that Vlissingen has to offer."
    }
    , {
        country: 'Netherlands',
        city: 'Breskens',
        name: 'Jachthaven Breskens',
        address: 'Noorderhaven 10, 4511 RC Breskens, Netherlands',
        resume: 'Your reliable haven on the Dutch coast.',
        lat: 51.36661,
        lng: 3.80614,
        maxLOA: '20 meters',
        maxDraft: '4 meters',
        amenities: [
            '24/7 Security',
            'Utilities',
            'Modern Facilities',
            'Fuel Station',
            'Restaurant & Bar',
            'Nearby Supermarket'
        ], key: '14',
        about: "Jachthaven Breskens offers a reliable and secure haven for boaters seeking a convenient location on the Dutch coast. Situated in the charming fishing village of Breskens, this marina caters to yachts ranging from 5 to 20 meters, with the possibility of accommodating larger visiting yachts upon prior arrangement."
    }
    , {
        country: 'Netherlands',
        city: 'Eelderwolde',
        name: 'Kaap Hoorn Yacht Harbour',
        address: 'Leeksterweg 152, 9363 VC Nuis, Netherlands',
        resume: 'Your peaceful haven in Drenthe.',
        lat: 53.14503,
        lng: 6.70014,
        maxLOA: '20 meters',
        amenities: [
            'Limited Amenities',
            'Utilities',
            'Winter Storage',
            'Maintenance Services',
            'Scenic Location',
            'Fishing Opportunities'
        ], key: '15',
        about: "Kaap Hoorn Yacht Harbour prioritizes tranquility and a connection with nature for boaters seeking a relaxing escape. Nestled near Eelderwolde in the Drenthe province, this marina caters to yachts up to 20 meters, offering a serene environment amidst Dutch landscapes. Surrounded by nature reserves and scenic waterways, the marina offers a peaceful escape from the hustle and bustle of city life. Fishing enthusiasts can enjoy the abundance of aquatic life in the surrounding waters."
    }
    , {
        country: 'Netherlands',
        city: 'Zoutkamp',
        name: 'Haven Hunzegat Zoutkamp',
        address: 'Strandweg 17, 9974 SM Zoutkamp, Netherlands',
        resume: 'Your convenient gateway to the Wadden Sea.',
        lat: 53.35850,
        lng: 6.20325,
        maxLOA: '250 meters',
        maxDraft: '2 meters',
        amenities: [
            '24/7 Security',
            'Utilities',
            'Modern Facilities',
            'Watersports Shop',
            'Playground & Picnic',
            'Proximity to Wadden Sea'
        ], key: '16',
        about: "Haven Hunzegat Zoutkamp, situated in the picturesque village of Zoutkamp, offers a convenient gateway to explore the wonders of the Wadden Sea National Park. This marina boasts a total of 250 berths, catering to various yacht sizes, with 40 dedicated spots specifically for transient yachts. Restaurants are situated within walking distance, while supermarkets are accessible by car for additional provisions. The marina's true gem lies in its proximity to the Wadden Sea National Park, a UNESCO World Heritage Site. This unique ecosystem offers exceptional opportunities for wildlife watching, hiking, and exploring the natural wonders of the region."
    }
    , {
        country: 'Netherlands',
        city: 'Workum',
        name: 'Workumer Jachthaven',
        address: 'De Hoop 2, 8711 AB Workum, Netherlands',
        resume: 'Experience the charm of Friesland and relax at Workumer Jachthaven.',
        lat: 52.88122,
        lng: 5.45614,
        maxLOA: '50 meters',
        amenities: [
            'All-Around Service',
            'Berths',
            'Winter Storage',
            'Refueling'
        ], key: '17',
        about: "Workumer Jachthaven, situated in the heart of Workum, Friesland, is a complete and modern watersports company offering a range of services for boaters. This marina boasts over 150 berths, catering to various yacht sizes, with the possibility of accommodating larger visiting yachts upon prior arrangement."
    }
    , {
        country: 'Netherlands',
        city: 'Niedorp',
        name: 'De Rijd Yacht Harbour',
        address: 'Specific address in Niedorp, Netherlands',
        resume: 'Relax and unwind amidst nature at De Rijd Yacht Harbour.',
        lat: 53.14503,
        lng: 6.70014,
        maxLOA: '20 meters',
        amenities: [
            'Limited Amenities',
            'Utilities',
            'Winter Storage',
            'Maintenance Services',
            'Scenic Location',
            'Fishing Opportunities'
        ], key: '18',
        about: "De Rijd Yacht Harbour prioritizes tranquility and a connection with nature for boaters seeking a relaxing escape. Nestled in Niedorp, this marina offers a serene environment amidst Dutch landscapes. Restaurants are accessible within a short driving distance, and the marina provides winter storage and potentially collaborates with contracted service providers for boat maintenance and repair."
    }
    , {
        country: 'Netherlands',
        city: 'Hoorn',
        name: 'Grashaven Yacht Harbour',
        address: 'Grashaven, 1621 HA Hoorn, Netherlands',
        resume: 'Discover the beauty and convenience of Grashaven Yacht Harbour in Hoorn.',
        lat: 52.63067,
        lng: 4.89133,
        maxLOA: '15 meters',
        amenities: [
            'Essential Amenities',
            'Utilities',
            'Shared Facilities',
            'Waste Disposal'
        ], key: '19',
        about: "Grashaven Yacht Harbour is situated in Hoorn, Netherlands, offering essential amenities for boaters. While specific details on the full range of services are limited online, boaters can expect key utilities and conveniences such as electricity and water connection, along with shared bathroom and waste disposal facilities."
    }
    , {
        country: 'Netherlands',
        city: 'Workum',
        name: 'Workumer Jachthaven',
        address: 'De Hoop 2, 8711 AB Workum, Netherlands',
        resume: 'Experience the charm of Friesland and relax at Workumer Jachthaven.',
        lat: 52.88122,
        lng: 5.45614,
        maxLOA: '50 meters',
        amenities: [
            'All-Around Service',
            'Berths',
            'Winter Storage',
            'Refueling',
            'Restaurant',
            'Nearby Supermarket'
        ], key: '20',
        about: "Workumer Jachthaven, situated in the heart of Workum, Friesland, is a complete and modern watersports company offering a range of services for boaters. This marina boasts over 150 berths, catering to various yacht sizes, with the possibility of accommodating larger visiting yachts upon prior arrangement."
    }
    , {
        country: 'Netherlands',
        city: 'Edam',
        name: 'De Zeevang Yacht Harbour',
        address: 'De Zeevang 3, 1135 HH Edam, Netherlands',
        lat: 52.50342,
        lng: 4.94506,
        maxLOA: '20 meters',
        amenities: [
            'Limited Amenities',
            'Utilities',
            'Winter Storage',
            'Maintenance Services',
            'Scenic Location',
            'Fishing Opportunities'
        ], key: '21',
        about: "De Zeevang Yacht Harbour prioritizes tranquility and a connection with nature for boaters seeking a relaxing escape. Situated near Edam, North Holland, this marina caters to yachts up to 20 meters, offering a serene environment amidst Dutch landscapes. Surrounded by nature reserves and scenic waterways, the marina offers a peaceful escape from the city life."
    }
    , {
        country: 'Netherlands',
        city: 'Utrecht',
        name: 'Winkeloord Yacht Harbour',
        address: 'Abcoude, Utrecht, Netherlands',
        lat: 52.13333,
        lng: 4.95000,
        maxLOA: '20 meters',
        amenities: [
            'Utilities',
            'Shared Facilities',
            'Waste Disposal'
        ], key: '22',
        about: "Winkeloord Yacht Harbour is situated in Abcoude, Netherlands, offering essential amenities to ensure a comfortable stay for boaters. While detailed information may be limited, essential services like electricity, water connection, and waste disposal facilities are available."
    }
    , {
        country: 'Netherlands',
        city: "Scheveningen (part of The Hague)",
        name: 'Marina Scheveningen',
        lat: 52.13333,
        lng: 4.30000,
        maxLOA: '18 meters',
        maxDraft: '4 meters',
        amenities: [
            'Utilities',
            'Shared Facilities',
            'Waste Disposal'
        ], key: '23',
        about: "Marina Scheveningen encompasses various entities within Scheveningen Harbour, offering berths for yachts and a range of services for recreational boating. The marina and yacht clubs provide members and visitors with essential amenities, social events, and sailing-related services, making it a vibrant community for sailing enthusiasts."
    }
    , {
        country: 'Netherlands',
        city: 'Arnhem',
        name: 'Valkenburg Yacht Harbour',
        address: 'Valkenburg Yacht Harbour, Arnhem, Gelderland, Netherlands',
        lat: 52.03094,
        lng: 5.96181,
        maxLOA: '20 meters',
        amenities: [
            'Utilities',
            'Shared Facilities',
            'Waste Disposal',
            'Winter Storage'
        ], key: '24',
        about: "Valkenburg Yacht Harbour prioritizes tranquility and a connection with nature for boaters seeking a relaxing escape. Located in a serene environment amidst the beautiful Dutch landscapes, the marina offers essential amenities including electricity, water connections, shared bathroom, and shower facilities, alongside waste disposal services. Winter storage services are also available for yachts during the colder months."
    }
    , {
        country: 'Netherlands',
        city: 'Bimmen, Gelderland',
        name: 'De Bijland Yacht Harbour',
        address: 'De Bijland Yacht Harbour, Bimmen, Netherlands',
        lat: 52.37000,
        lng: 5.94667,
        maxLOA: '15 meters',
        amenities: [
            'Utilities',
            'Shared Facilities',
            'Waste Disposal'
        ], key: '25',
        about: "De Bijland Yacht Harbour is a smaller marina located in Bimmen, catering to recreational boaters. Despite its size, the marina provides essential services such as electricity, water connections, and shared bathroom and shower facilities, ensuring a comfortable stay for its visitors."
    }

];



export default marinas