import React from 'react'
import './Banner.css'

const Banner = () => {
    return (
        <div id="Banner" className="banner">
            <div className="banner-wrap">
                <div className="notice-text">Our support team is available online 24/7.</div>
            </div>
        </div>
    )
}

export default Banner