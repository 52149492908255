import React from 'react';
// Import images
import Step1Image from '../images/Step-01.png';
import Step2Image from '../images/Step-02.png';
import Step3Image from '../images/Step-03.png';

const Howitworks = () => {
    return (
        <div className="content-section">
            <div className="container">
                <div className="title-wrap-centre">
                    <h2>How it works.</h2>
                </div>
                <div className="w-layout-grid works-grid">
                    <div className="content-card">
                        <img src={Step1Image} alt="Adapt your menu items" className="step-image" />
                        <div className="content-wrapper">
                            <h5>Search and Find Your Marina</h5>
                            <p>Start by locating your preferred marina using our Maps search feature. This will provide you with all the necessary information.</p>
                        </div>
                    </div>
                    <div className="line-column">
                        <div className="line"></div>
                    </div>
                    <div className="content-card">
                        <img src={Step2Image} alt="Accept online orders & takeout" className="step-image" />
                        <div className="content-wrapper">
                            <h5>Book Your Spot</h5>
                            <p>After choosing your ideal marina, secure your spot directly through the app. The process is straightforward and secure, guaranteeing a place for your vessel.</p>
                        </div>
                    </div>
                    <div className="line-column">
                        <div className="line"></div>
                    </div>
                    <div className="content-card">
                        <img src={Step3Image} alt="Manage delivery or takeout" className="step-image" />
                        <div className="content-wrapper">
                            <h5>Enjoy Your Stay</h5>
                            <p>With your booking confirmed, simply relax and make the most of your maritime experience. Explore, enjoy the marina's amenities, and immerse in your adventure.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Howitworks;
