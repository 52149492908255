import React from 'react';
import './InfoPage2.css';
import ComponentInfo from '../Components/ComponentInfo'
const InfoPage2 = ({ point, setSelectedMarker, isMobile }) => {
    return (

        !isMobile ?
            (<div >

                <ComponentInfo marinaData={point} window="true" />
            </div>


            )
            :
            (
                <div className="infoStyle">
                    <h2>{point.name}</h2>
                    <p>{point.resume}</p>
                    <a href={`/info/${point.key}`} className="button_infomaps">More information</a>
                </div>
            )


    );
}

export default InfoPage2;
