"use client"

import React from 'react';
import './App.css';
import { Maps } from './Components/Maps'
import { Maps2 } from './Components/Maps2'
import MarinaInfo from './Components/MarinaInfo'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RegistrationForm from './Components/RegistrationForm'
import ConfirmationPage from './Components/ConfirmationPage'
import LandingPage from './Landingpage/LandingPage'
import PageInfoMobile from './Components/PageInfoMobile'


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} exact />
        <Route path="/confirmation" element={<ConfirmationPage />} />
        <Route path="/reg/:id" element={<RegistrationForm />} />
        <Route path="/landingpage" element={<LandingPage />} />
        <Route path="/info/:id" element={<PageInfoMobile />} />

      </Routes>
    </Router>
  );
}

export default App;
