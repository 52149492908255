import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Typography, Button, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import SailingOutlinedIcon from '@mui/icons-material/SailingOutlined';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import marinas from '../data/marina';
import CircularProgress from '@mui/material/CircularProgress';
import { utcToZonedTime } from 'date-fns-tz'
import { format } from 'date-fns'
import logo_bookmymarina from '../images/logo_bookmymarina.png'
import Divider from '@mui/material/Divider';
import { Skeleton, Stack } from '@mui/material';


const EffectBox = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    backdropFilter: 'blur(10px)',
    padding: theme.spacing(4),
    paddingTop: '0px',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: 350,
    margin: 'auto',
    overflowX: 'hidden'
}));



const TextFieldStyled = styled(TextField)(({ theme }) => ({
    fontFamily: 'Roboto'
}));

function RegistrationForm() {
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const timerId = setTimeout(() => {
            setTimer(false);
        }, 1000);


        return () => clearTimeout(timerId);
    }, []);

    const { id } = useParams();
    const marinaData = marinas.find((marina) => marina.key == id);
    const navigate = useNavigate();
    const parisDate = utcToZonedTime(new Date(), 'Europe/Paris')

    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        vesselName: '',
        vesselDraft: '',
        vesselLength: '',
        arrivalDate: '',
        departureDate: '',
        marina: marinaData.name,
        createdDate: parisDate,
    });

    const [errors, setErrors] = useState({});

    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const phoneRegex = /^\+?\d{10,15}$/;

    const validate = () => {
        let tempErrors = {};
        tempErrors.firstName = formValues.firstName ? "" : "This field is required";
        tempErrors.lastName = formValues.lastName ? "" : "This field is required";
        tempErrors.email = formValues.email ? (emailRegex.test(formValues.email) ? "" : "Invalid email format ") : "This field is required";
        tempErrors.phone = formValues.phone ? (phoneRegex.test(formValues.phone) ? "" : "Invalid format. Use '+', e.g., +1234567890.") : "This field is required";
        tempErrors.vesselName = formValues.vesselName ? "" : "This field is required";
        tempErrors.vesselDraft = formValues.vesselDraft ? "" : "This field is required";
        tempErrors.vesselLength = formValues.vesselLength ? "" : "This field is required";
        tempErrors.arrivalDate = formValues.arrivalDate ? "" : "This field is required";
        tempErrors.departureDate = formValues.departureDate ? "" : "This field is required";

        setErrors(tempErrors);

        return Object.values(tempErrors).every(x => x === "");
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            setIsLoading(true);
            setFormValues({ ...formValues, marina: marinaData.name });
            console.log(formValues);

            fetch('https://script.google.com/macros/s/AKfycbwYk6oKwqSMbLoCrljQ4nVI-RSkMaWHEJVdRn6VIZKdSuESMk_3ajP3DZ295ZNP1c7ogw/exec', {
                method: 'POST',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formValues),
            })

                .then(data => {
                    console.log('Success:', data);
                    navigate('/confirmation');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    // Gérer les erreurs ici, comme afficher un message à l'utilisateur
                });
        }
    };


    return (
        timer ? (
            <Stack spacing={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton style={{ margin: '10px' }} variant="rectangular" width={210} height={60} />
                <Skeleton style={{ margin: '10px' }} variant="rectangular" width={210} height={60} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={210} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />

            </Stack>
        ) : (
            <div>
                <div style={{ backgroundColor: '#1976d2', height: '14px', width: '100%', marginBottom: '22px' }}></div>
                <EffectBox component="form" onSubmit={handleSubmit} noValidate>
                    <img src={logo_bookmymarina}
                        alt="logo" />
                    <Divider />

                    <h1 className='title_info center'>{marinaData.name}</h1>

                    <Typography paddingBottom="0px">Contact Information:</Typography>

                    {[
                        { type: 'text', id: "firstName", icon: <PersonIcon style={{ color: 'var(--brand)' }} />, label: "First Name", name: "firstName" },
                        { type: 'text', id: "lastName", icon: <PersonIcon style={{ color: 'var(--brand)' }} />, label: "Last Name", name: "lastName" },
                        { type: 'email', id: "email", icon: <EmailOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Email", name: "email" },
                        { type: 'phone', id: "phone", icon: <PhoneAndroidOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Phone", name: "phone" },
                    ].map((field, index) => (
                        <TextFieldStyled
                            key={field.id}
                            id={field.id}
                            name={field.name}
                            label={field.label}
                            type={field.type}
                            value={formValues[field.name]}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: <InputAdornment style={{ color: 'var(--brand)' }} position="start">{field.icon}</InputAdornment>,
                            }}
                            variant="standard"
                            error={!!errors[field.name]}
                            helperText={errors[field.name]}
                            required
                        />
                    ))}

                    <Typography paddingBottom="0px">Vessel Information:</Typography>
                    {[
                        { type: 'text', id: "vesselName", icon: <SailingOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Vessel Name", name: "vesselName" },
                        { type: 'number', id: "vesselDraft", icon: <VerticalAlignBottomOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Vessel Draft (m)", name: "vesselDraft" },
                        { type: 'number', id: "vesselLength", icon: <StraightenOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Vessel Length (m)", name: "vesselLength" },
                    ].map((field, index) => (
                        <TextFieldStyled
                            key={field.id}
                            id={field.id}
                            name={field.name}
                            label={field.label}
                            type={field.type}
                            value={formValues[field.name]}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: <InputAdornment style={{ color: 'var(--brand)' }} position="start">{field.icon}</InputAdornment>,
                            }}
                            variant="standard"
                            error={!!errors[field.name]}
                            helperText={errors[field.name]}
                            required
                        />
                    ))}

                    <Typography paddingBottom="0px">Reservation Dates:</Typography>
                    {[
                        { id: "arrivalDate", icon: <DirectionsBoatFilledOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Arrival Date", name: "arrivalDate", type: "date" },
                        { id: "departureDate", icon: <DirectionsBoatFilledOutlinedIcon style={{ color: 'var(--brand)' }} />, label: "Departure Date", name: "departureDate", type: "date" },
                    ].map((field, index) => (
                        <TextFieldStyled
                            key={field.id}
                            id={field.id}
                            name={field.name}
                            label={field.label}
                            type={field.type}
                            value={formValues[field.name]}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">{field.icon}</InputAdornment>,
                            }}
                            variant="standard"
                            error={!!errors[field.name]}
                            helperText={errors[field.name]}
                            required
                        />
                    ))}

                    <Button style={{ maxWidth: '100vw', minHeight: '12px' }} className="button w-button" type="submit" variant="contained" disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : 'SUBMIT'}
                    </Button>

                </EffectBox>
            </div>)
    );
}

export default RegistrationForm;
