import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Stack } from '@mui/material';
import marinas from '../data/marina';
import logo_bookmymarina from '../images/logo_bookmymarina.png';
import TangerMarinaImage from '../images/3.png';

import ComponentInfo from './ComponentInfo';

import './PageInfoMobile.css';

function PageInfoMobile() {
    const { id } = useParams();
    const [timer, setTimer] = useState(true);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setTimer(false);
        }, 1000);


        return () => clearTimeout(timerId);
    }, []);

    const marinaData = marinas.find((marina) => marina.key === id);

    return (
        timer ? (
            <Stack spacing={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Skeleton style={{ margin: '10px' }} variant="rectangular" width={210} height={60} />
                <Skeleton variant="rounded" width={500} height={150} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={210} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={30} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={130} />
                <Skeleton style={{ marginTop: '30px' }} variant="rectangular" width={310} height={2130} />
            </Stack>
        ) : (
            <>
                <div className='wrapper_logo'>
                    <img src={logo_bookmymarina} className="logo_center" alt="logo" />
                </div>
                <img src={TangerMarinaImage} alt="image_marina" />
                <ComponentInfo marinaData={marinaData} />
            </>
        )
    );
}

export default PageInfoMobile;
